// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationZH from "./locales/zh-CN/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  ja: {
    translation: translationJA,
  },
  "zh-CN": {
    translation: translationZH,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // 使用语言检测器
  .init({
    resources,
    // 自动检测浏览器语言并设置为默认语言
    detection: {
      order: ["navigator"],
    },
    lng: "en", // 默认语言
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
