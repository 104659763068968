// src/App.js
import React from "react";
import { useTranslation } from "react-i18next";
import Bot from "./components/Bot";
import LanguageSelector from "./components/LanguageSelector"; // 根据实际文件路径引入组件

const App = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="App">
      <h1>{t("welcome")}</h1>
      <p>{t("botOpening")}</p>
      <LanguageSelector />
      <Bot />
    </div>
  );
};

export default App;
